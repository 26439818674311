// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
import * as slick from "slick-carousel";

window.jQuery = $;
window.$ = $;

// Required
require("bootstrap");

jQuery( document ).ready(function() {

    // Add border and shadow on header on scroll
    jQuery(window).scroll(function() {
        if (jQuery(document).scrollTop() > 1) {
            jQuery('header').addClass('header-scroll');
        } else {
            jQuery('header').removeClass('header-scroll');
        }
    });

    // Home Slider
    if (jQuery(".home-slider").length) {

        jQuery(".home-slider").slick({

            // normal options...
            speed:500,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'linear',
            autoplay: false,
            arrows: false,
            dots:true,
            fade:true
        });
    }

    $('.home-slider').on('beforeChange', function(event, { slideCount: count }, currentSlide, nextSlide){
      jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-inner').fadeOut(750);
      jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-top-secondary').delay(750).fadeOut(750);
      jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-mid-secondary').delay(1500).fadeOut(750);
    });

    $('.home-slider').on('afterChange', function(event, { slideCount: count }, currentSlide, nextSlide){
        jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-mid-secondary').fadeIn(750);
        jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-top-secondary').delay(750).fadeIn(750);
        jQuery(".slick-slide[data-slick-index='"+currentSlide+"']").find('.hero-inner').delay(1750).fadeIn(750);
    });


    if (jQuery(".client-slider").length) {

        jQuery(".client-slider").slick({

            // normal options...
            speed: 5000,
            infinite: true,
            slidesToShow: 8,
            centerMode: true,
            slidesToScroll: 8,
            cssEase: 'linear',
            autoplay: true,
            arrows: false,
            dots:false,
            autoplaySpeed: 0,

            // the magic
            responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                }

            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                }

            }, {

                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                }

            }, {

                breakpoint: 300,
                settings: "unslick" // destroys slick

            }]
        });
    }

    // Prism Nav Slider
    if (jQuery(".prism-nav").length) {

        let hash = window.location.hash;
        let initialSlide = 0;
        if(hash == '#we-make') {
            initialSlide = 3;
        }
        if(hash == '#we-fit') {
            initialSlide = 6;
        }

        jQuery(".prism-nav").slick({

            // normal options...
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'linear',
            autoplay: false,
            arrows: true,
            dots:false,
            asNavFor: '.prism-slider',
            initialSlide: initialSlide,
            nextArrow: '.prism-next',
        });
    }
    // Prism Main Slider
    if (jQuery(".prism-slider").length) {

        let hash = window.location.hash;
        let initialSlide = 0;
        if(hash == '#we-make') {
            initialSlide = 3;
        }
        if(hash == '#we-fit') {
            initialSlide = 6;
        }

        jQuery(".prism-slider").slick({

            // normal options...
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'linear',
            autoplay: false,
            arrows: false,
            dots:false,
            asNavFor: '.prism-nav',
            initialSlide: initialSlide
        });
    }


    // People Nav Slider
    if (jQuery(".people-nav").length) {

        jQuery(".people-nav").slick({

            // normal options...
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            cssEase: 'linear',
            autoplay: false,
            arrows: false,
            dots:false,
            asNavFor: '.people-slider',
            focusOnSelect: true,

            // the magic
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6,
                    }

                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }

                }, {

                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.5,
                    }
                }
            ]
        });
    }
    // People Main Slider
    if (jQuery(".people-slider").length) {

        jQuery(".people-slider").slick({

            // normal options...
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'linear',
            autoplay: false,
            arrows: false,
            dots:false,
            asNavFor: '.people-nav'
        });
    }
});
